<template>
	<v-app>		
		<router-view></router-view>
	    <div class="rtl-layout" @click="toggleRTLLayout">
	      <!-- <a class="text-xl" href="javascript:void(0);">RTL</a> -->
	    </div>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import axios from 'axios';
export default {
	computed: {
		name: "access token",
		created() {
   return this.getAccessToken();
		},
		...mapGetters(["rtlLayout"]),
		mobileMenu: {
			get() {
				return this.$store.getters.sidebarOpen;
			},
			set(val) {
				this.$store.dispatch("toggleSidebar", val);
			}
		}
	},
	/**
	 * Method To set the Rtl While page is opened
	*/
	 mounted() {

		if(this.rtlLayout) {
			this.$vuetify.rtl = this.rtlLayout;
		}
		this.myMessage();
		this.getAccessToken();
		const token = localStorage.getItem('now')
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
		const cart = JSON.parse(localStorage.getItem('cart'))
		if (cart) {
		this.$store.commit({
			type:'onAddProductToC', 
			dart:cart 
		})
		}
		const wishlist = JSON.parse(localStorage.getItem('wishlist'))
		if (wishlist) {
			this.$store.commit({
			type:'onAddItemToWish', 
			wishlist:wishlist 
		})
		}

	},
	methods: {
		/**
		 * Method To Toggle The RTL Layout
		 */
		myMessage() {
			console.log("hello form this dot my message");
		},
		async getAccessToken() {
			try {
      let token = "";
    //   await fetch("https://byihuse-0c0k.onrender.com/routes")
	//   to be uncommented when the when needed 
      await fetch("https://byihuse-0c0k.onrender.com/routes/pay/token")
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          token = data;
		  console.log("the following is token");
      console.log(token);
      localStorage.setItem('collectionToken', token.access_token);
	  localStorage.setItem('disbursmentToken', token.disbursment_token);
        })
        .catch((error) => {
          console.log(error);
        });
		console.log("the following is token");
		console.log(token);
      return token;
    } catch (error) {
      throw error;
    }
		},
		toggleRTLLayout() {
			this.$vuetify.rtl = !this.rtlLayout;
			this.$store.dispatch("changeRtlLayout");
		},
	}
}
</script>


