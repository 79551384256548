export default {
	"home":"Accueil",
	"homeOne":"Première maison",
	"homeTwo":"Maison deux",
	"homeThree":"Maison trois",
	"userProfile":"Compte d'utilisateur",
	"shop":"Boutique",
	"productDetails": "détails du produit",
	"cart" : "Chariot",
	"checkout":"Check-out",
	"payment":"Paiement",
	"fashion":"Mode",
	"gadgets":"Gadgets",
	"accessories":"Accessories",
	"categories":"Catégories",
	"men":"Männer",
	"jean":"Jean",
	"jackets":"Vestes",
	"shirt":"Chemise",
	"tShirt":"T-shirt",
	"women":"Femmes",
	"dress":"Robe",
	"dresses":"Robes",
	"headphone":"casque de musique",
	"smartphone":"Téléphone intelligent",
	"watch":"Regarder",
	"speaker":"Orateur",
	"laptopAccessories":"Accessoires pour ordinateur portabl",
	"belts":"Ceintures",
	"jewellery":"Bijoux",
	"purse":"Bourse",
	"pages":"Pages",
	"about":"Sur",
	"termAndCondition":"Termes et conditions",
	"privacyPolicy":"Politique de confidentialité",
	"blogDetail":"Détail du blog",
	"faq":"FAQ",
	"Page":"Page",
	"404Page":"404 Page",
	"session":"Session",
	"signIn":"Se connecter",
	"register":"Registre",
	"forgotPassword":"Mot de passe oublié",
	"thankYou":"Je vous remercie",
	"contactUs":"Contactez nous",
	"social":"Social",
	"facebook":"Facebook",
	"twitter":"Twitter",
	"youtube":"Youtube",
	"googlePlus":"Google Plus",
	"reports":"Rapports",
	  "invoices":"Factures",
	  "profile":"Profil",
	  "products":"Des produits",
	  "productAdd":"Ajout de produit",
	"goToSite":"Aller au site",
	"adminPanel":"panneau d'administration",
	"edit": "modifier",
	"FeaProducts": "Produits populaires",
	"NewArrival": "Nouvelles Arrivées",
	"DenimPullover": "",
	  "Nowonly": "seulement maintenant",
	"dealDayTitle": "",
	"wellcome": "Made in Rwanda",
	"subTitleOne": "Livraison gratuite",
	  "titleOne": "Commandez aujourd'hui et recevez-le aujourd'hui!",
	  "descOne": "* Toute commande supérieure à 1000 RWF",
	  "subTitleTwo": "BON SOIN CLIENT",
	  "titleTwo": "Assistance dédiée 24/7",
	  "descTwo": "* Nous sommes prêts à vous aider à tout moment",
	  "subTitleThree": "SÉCURISE",
	  "titleThree": "En ligne sécurisé ou simplement en espèces",
	"descThree": "* C'est facile, les conditions s'appliquent",
	"sectitle": "Byoroshye Nettoyage à sec",
	"subtitle": "Nettoyage à sec rapide et facile",
	"metainfo": " 1000",
	"metaainfo": "à partir de seulement",
	"paragraph":"Byihuse.rw à travers FINVIA GROUP ltd offre les services de nettoyage à sec les plus rapides à Kigali, mais aux meilleurs prix. Nous prenons même vos vêtements chez vous et vous les livrons après. Appelez-nous et laissez-nous vous aider.",
	
	"sale": "Vente",
	"Womencollection": "Women collection",
	"discountNumber":"50% Off",
	"arrivalTitle": "Nouvelle arrivee",
	"flat": "Flat 50",
	"discount": "remise",
	"Mencollection": "Men's collection",
	"off": "50% Off",
	"heading": "Achetez avec nous 'byihuse' ici ou appelez-nous simplement au +250784481653",
	"description": "Restez à jour avec nos dernières nouveautés et produits",
	"footeTittle": "Sur Byihuse",
	"footerDesc": "Byihuse est un e-commerce de FINVIA Group Ltd spécialisé dans les produits made in Rwanda. Il relie les clients aux industries. Ce faisant, Il assure les meilleurs prix du marché pour ses clients ",
	"AboutUs": "Sur",
	"subHeadingAboutUs":"Nous sommes là pour rendre tous vos achats amusants!",
	"OurMission":"Notre mission",
	"Providing":"Offrir la meilleure expérience d'achat en ligne",
	"Through": "Grâce à une application Web et mobile facile à utiliser, au service client et à la livraison, nous vous offrons la meilleure expérience d'achat en ligne",
	"MadeinRwanda": "Fabriqué au Rwanda",
	"Weconnect": "Nous vous connectons avec les industries rwandaises et d'autres entreprises utilisant la technologie",
	"Weworkclosely": "Nous travaillons en étroite collaboration avec des entreprises au Rwanda pour vous obtenir les meilleures offres, vous ne pouvez parler directement à ces entreprises",
	"WithFINVIA": "Avec FINVIA Group Ltd, nous offrons:",
	"Turamuyawe": "Tura muyawe, nous construirons plus de 100 maisons pour soutenir la politique gouvernementale de vivre dans des villages modernes",
	"Weprovide":"Nous fournissons des techniciens professionnels en plomberie, électricité, lutte contre les incendies, conduite automobile et bien d'autres",
	"Cleaningclothes":"Nettoyage des vêtements et repassage. Nous les récupérons chez vous et les déposons plus tard",
	"Ourservices": "Nos services",
	"ContactUs": "Nous contacter",
	"subHeadingContactUs":"Partagez vos commentaires avec nous.",
	"ContactInfo": "Info de contact",
	"Salesteam": "Équipe de vente",
	"Call": "Appel",
	"Mail": "Mail",
	"Address": "Adresse",
	"WritetoUs":"Écrivez-nous",
	"SendMessage": "Envoyer le message",
	"Rental": "la location",
	"subHeadingRental":"Utilisez notre location et minimisez vos défis de vie",
	"Fillthisform":"Remplissez ce formulaire pour demander cette location",
	"RentingHour":":Heures de location",
	"Startingatonly":"À seulement",
	"Submit":"Soumettre",
	"Fillservice":"Remplissez ce formulaire pour demander ce service",
	"Services": "Services",
	"Useourservices":"Utilisez nos services",
	"Hi":"Salut",
	"OrderHistory":"Historique des commandes",
	"Profile":"Profil",
	"EditNames":"Modifier les noms",
	"EditEmails":"Modifier l'e-mail",
	"EditPassword":"Modifier le mot de passe",
	"Orderdetails":"Détails de la commande",
	"Close":"Proche",
	"DeliverTo":"Livrer à",
	"ProductImage":"Image",
	"ProductName":"Nom du produit",
	"Quantity":"Quantité",
	"Price":"Prix",
	"Subtotal":"Sous-total",
	"Delivery":"Livraison",
	"Tax":"Impôt",
	"Total":"Total",
	"LogIn":"S'identifier",
	"LogOut":"Se déconnecter",
	"UserProfile":"Profil de l'utilisateur",
	"NoProductFound":"Aucun produit trouvé",
	"Addalltocart":"Tout ajouter au panier",
	"Checkout":"Check-out",
	"EditEmailInformation":"Modifier les informations de l'e-mail",
	"Edit":"Modifier",
	"EditNamesInformation":"Modifier les informations sur les noms",
	"EditProfilePasswordInformation":"Modifier les informations de mot de passe du profil",
	"ProfileInformation":"Informations sur le profil",
	"UserSignIn":"Connexion utilisateur",
	"RememberMe":"Souviens-toi de moi",
	"DontHaveaccount":"Vous n'avez pas de compte?",
	"SignIn":"Se connecter",
	"Clickheretocreateone":"Cliquez ici pour en créer un",
	"SignUp":"S'inscrire",
	"Alreadyhaveaccount":"Vous avez déjà un compte? puis",
	"EnterYourDetails":"Entrez vos détails",
	"Thankyouforshopping":"Merci de magasiner avec nous. Notre équipe commerciale vous appellera dans une minute pour le processus de livraison",
	"SpreadTheWord":"Faire connaitre",
	"Backtoshop":"Retour à la boutique",
	"Additionalservice":"Service additionnel: ",
	"AddToWishList":"Ajouter à la liste de souhaits",
	"ViewCart":"Voir le panier",
	"AddToCart":"Ajouter au chariot",
	"ShareNow":"Partage maintenant",
	"YouMightAlsoLike":"Vous pourriez aussi aimer",
	"ShowAll":"Afficher tout",
	"GotoShopping":"Aller faire du shopping",
	"YourShoppingBagisempty":"Votre panier est vide",
	"cartHeading":"Voici ce qu'il y a dans votre sac",
	"subHeadingCart":" ",
	"deleteCartProduct":"Êtes-vous sûr de vouloir supprimer ce produit? ",
	"Cancel":"Annuler",
	"Yes":"Oui",
	"PaymentInformation":"Informations de paiement",
	"DeliveryAddress":"entrez votre adresse de livraison",
	"EnterContact":"Entrez les informations de contact",
	"ContinuePayment":"Continuer vers le paiement",
	"AddAgent":"Ajoutez le code d'agent si vous l'avez",
	"OfferCode":"Code d'agent",
	"OfferThanks":"Remerciez l'agent en ajoutant son code",
	"*ifagent":"*si vous nous avez été référé par un agent",
	"pleaseagentcode":"veuillez indiquer son code d'agent",
	"Here":"Ici",
	"PaymentOptions":"Options de paiement",
	"MobileMoney":"Argent mobile",
	"PaywithCash":"Payer en argent comptant",
	"PayWithMobileMoney":"Payer avec Mobile Money",
	"Clear":"Vider",
	"FreeDelivery":"Livraison gratuite",
	"productsinfewhours":"obtenez vos produits en quelques heures",
	"PremiumDelivery":"Livraison Premium",
	"getorderrightaway":"recevez votre commande tout de suite",
	"ShowOrderDetail":"Afficher le détail de la commande",
	"Youhave":"Tu as",
	"itemscart":"Articles dans votre panier",
	"NoItemFound":"Aucun élément trouvé",
	"searchNoproductFound":"Si vous ne trouvez pas ce que vous cherchez, appelez le +250788 125 096",
	"Loading":"Chargement",
	"Paywithdebit": "Accepter des produits sur une dette",
  }