import axios from 'axios'

const env = process.env.NODE_ENV || 'dev'

let host = ''

if (env === 'production') {
  //  host = 'https://byihuse.rw'
  //  host = "https://api.akira.rw";
  //  host = "https://fragile-trench-coat-lion.cyclic.app/";
  //  host = "http://localhost:4001/";
   host = "https://byihuse-bn.onrender.com";

  //  host = "https://byihuse-bn.onrender.com/";
} else {
  //host = 'http://localhost:4000'
  //  host = 'Http://localhost:4000'
  //  host = "https://fragile-trench-coat-lion.cyclic.app/";
  // https://byihuse-bn.onrender.com
  //  host = "http://localhost:4001/";
  host = "https://byihuse-bn.onrender.com";
  //  host = "https://byihuse-bn.onrender.com/";
  //  host = "https://api.akira.rw";
}

export default () => {
  return axios.create({
    baseURL: host
  })
}
