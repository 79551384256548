import en from './en'
import fr from './fr'
import swah from './kiswahhili'
// import jp from './kinyarwanda'
import kiny from './kinyarwanda'

export default {
    en: {
        message: en
    },  
    fr: {
        message: fr
    },
    swah: {
        message: swah
    },
    // jp: {
    //     message: jp
    // },
    kiny:{
        message: kiny
    }
}