export default {
	"home":"Home",
	"homeOne":"Home One",
	"homeTwo":"Home Two",
	"homeThree":"Home Three",
	"userProfile":"User Profile",
	"shop":"Shop",
	"productDetails": "Product Details",
	"cart": "Cart",
	"checkout":"Checkout",
	"payment":"Payment",
	"fashion":"Fashion",
	"gadgets":"Gadgets",
	"accessories":"Accessories",
	"categories":"Categories",
	"men":"Men",
	"jean":"Jean",
	"jackets":"Jackets",
	"shirt":"Shirt",
	"tShirt":"T-Shirt",
	"women":"Women",
	"dress":"Dress",
	"dresses":"Dresses",
	"headphone":"Headphone",
	"smartphone":"Smartphone",
	"watch":"Watch",
	"speaker":"Speaker",
	"laptopAccessories":"Laptop Accessories",
	"belts":"Belts",
	"jewellery":"Jewellery",
	"purse":"Purse",
	"pages":"Pages",
	"about":"About Us",
	"termAndCondition":"Term and Condition",
	"privacyPolicy":"Privacy Policy",
	"blogDetail":"Blog Detail",
	"faq":"FAQ",
	"Page":"Page",
	"404Page":'404 Page',
	"session":"Session",
	"signIn":"Sign In",
	"register":"Register",
	"forgotPassword":"Forgot Password",
	"thankYou":"Thank You",
	"contactUs":"Contact Us",
	"social":"Social",
	"facebook":"Facebook",
	"twitter":"Twitter",
	"youtube":"Youtube",
	"googlePlus":"Google Plus",
	"reports":"Reports",
	"invoices":"Invoices",
	"profile":"Profile",
	"products":"Products",
	"productAdd":"Product Add",
	"goToSite":"Go To Site",
	"adminPanel":"Admin Panel",
	"edit":	"Edit",
	"rental":"Renting",
	"service": "Service",
	"FeaProducts": "Featured Products",
	"NewArrival": "New Arrivals",
	"Dealoftheday": "Byoroshye Dry cleaning",
	"DenimPullover": "Fast and easy dry cleaning",
	"Nowonly": "starting at only ",
	"dealDayTitle": "Byihuse.rw through FINVIA GROUP ltd offers the fastest dry cleaning services in Kigali yet at the best prices. We even pick your clothes from your house and deliver them after. Call us and let us help you",
	"wellcome": "Made in Rwanda",
	"subTitleOne": "FREE delivery",
	"titleOne": "Order today and get it today!",
	"descOne": "*All orders over RWF 1000",
	"subTitleTwo": "GOOD CUSTOMER CARE",
	"titleTwo": "24/7 Dedicated Support",
	"descTwo": "*We are ready to help you any time",
	"subTitleThree": "SECURE",
	"titleThree": "Pay online secure or just cash",
	"descThree": "*It is easy, Conditions Apply",
	"sectitle": "Byoroshye Dry cleaning",
  "subtitle":"Fast and easy dry cleaning",
  "metainfo": "1000",
  "metaainfo": "starting at only ",
  "paragraph":"Byihuse.rw through FINVIA GROUP ltd offers the fastest dry cleaning services in Kigali yet at the best prices. We even pick your clothes from your house and deliver them after. Call us and let us help you.",
  "sale": "Sale",
  "Womencollection": "Women collection",
  "discountNumber":"50% Off",
  "arrivalTitle": "New Arrival",
  "flat": "Flat 50",
  "discount": "Discount",
  "Mencollection": "Men's collection",
  "off": "50% Off",
  "heading": "Shop with us 'byihuse' here or simply Call us on +250788 125 096",
  "description": "Stay up to date with our latest new and products",
  "footeTittle": "About byihuse",
  "footerDesc": "Byihuse is an e-commerce of FINVIA Group Ltd specialised in made in Rwanda products. It connects clients to industries. Doing so, it ensures best prices on the market for its clients",
  "services": "services",
  "byiza": "Byiza",
  "byoroshye": "Byoroshye",
  "byizewe": "Byizewe",
  "drycleaning": "Dry cleaning",
  "AboutUs": "About Us ",
  "subHeadingAboutUs":"We are here to make all your shopping fun! ",
  "OurMission":"Our Mission",
  "Providing":"Providing the best online shopping experience",
  "Through": "Through easy to use web and mobile application, customer care and delivery we give you the best online shopping experience ",
  "MadeinRwanda": "Made in Rwanda",
  "Weconnect": "We connect you with Rwandan industries and other companies using technology",
  "Weworkclosely": "We work closely with companies in Rwanda to get you the best deals, you can only get talking to those companies directly ",
  "WithFINVIA": "With FINVIA Group Ltd we offer:",
  "Turamuyawe": "Tura muyawe, We will build over a 100 houses to support goverment policy of living in modern villages ",
  "Weprovide":" We provide professional technicians in plumbing, electricity, fire fighting, car driving and many more ",
  "Cleaningclothes":" Cleaning clothes and ironing. We pick them up from your place nad drop them later",
  "Ourservices": "Ourservices",
  "ContactUs": "Contact Us",
  "subHeadingContactUs":"Share your feedback with us.",
  "ContactInfo": "Contact Info",
  "Salesteam": "Sales team", 
  "Call": "Call",
  "Mail": "Mail",
  "Address": "Address",
  "WritetoUs":"Write to Us",
  "SendMessage": "Send Message ",
  "Rental": "Rental", 
  "subHeadingRental":"Use our rental and minimize your life challenges.",
  "Fillthisform":"Fill this form to request this Rental",
  "RentingHour":":Renting Hour",
  "Startingatonly":"At only",
  "Submit":"Submit ",
  "Fillservice":"Fill this form to request this service",
  "Services": "Services",
  "Useourservices":"Use our services. ",
  "Hi":"Hi",
  "OrderHistory":"Order History ",
  "Profile":"Profile",
  "EditNames":"Edit Names",
  "EditEmails":"Edit Emails",
  "EditPassword":"Edit Password",
  "Orderdetails":"Order details",
  "Close":"Close",
  "DeliverTo":"Deliver To",
  "ProductImage":"Product Image",
  "ProductName":"Product Name",
  "Quantity":"Quantity",
  "Price":"Price",
  "Subtotal":"Subtotal",
  "Delivery":"Delivery",
  "Tax":"Tax",
  "Total":"Total",
  "LogIn":"LogIn",
  "LogOut":"LogOut ",
  "UserProfile":"User Profile ",
  "NoProductFound":"No Product Found ",
  "Addalltocart":"Add all to cart",
  "Checkout":"Checkout",
  "EditEmailInformation":"Edit Email Information",
  "Edit":"Edit",
  "EditNamesInformation":"Edit Names Information",
  "EditProfilePasswordInformation":"Edit Profile Password Information",
  "ProfileInformation":"Profile Information ",
  "UserSignIn":"User Sign In",
  "RememberMe":"Remember Me ",
  "DontHaveaccount":"Don't Have account?",
  "SignIn":"Sign In",
  "Clickheretocreateone":"Click here to create one",
  "SignUp":"Sign Up",
  "Alreadyhaveaccount":"Already have account? then",
  "EnterYourDetails":"Enter Your Details",
  "Thankyouforshopping":"Thank you for shopping with us. Our sales team will call you in a minute for delivery process",
  "SpreadTheWord":"Spread The Word",
  "Backtoshop":"Back to shop",
  "Additionalservice":"Additional service: ",
  "AddToWishList":"Add To WishList ",
  "ViewCart":"View Cart ",
  "AddToCart":"Add To Cart ",
  "ShareNow":"Share Now ",
  "YouMightAlsoLike":"You Might Also Like ",
  "ShowAll":"Show All ",
  "GotoShopping":"Go to Shopping",
  "YourShoppingBagisempty":"Your Shopping Bag is empty",
  "cartHeading":"Here's what's in your bag ",
  "subHeadingCart":" ",
  "deleteCartProduct":"Are you sure you want to delete this product? ",
  "Cancel":"Cancel ",
  "Yes":"yes ",
  "PaymentInformation":"Payment Information",
  "DeliveryAddress":"Enter Your Delivery Address",
  "EnterContact":"Enter Contact Information",
  "ContinuePayment":"Continue To Payment",
  "AddAgent":"Add Agent code if you have it",
  "OfferCode":"Agent Code",
  "OfferThanks":"Offer Thanks To Agent By Add his/her code",
  "ifagent":"*if you were referred to us by an agent",
  "pleaseagentcode":"please provide his/her agent code",
  "Here":"Here",
  "PaymentOptions":"Payment Options",
  "MobileMoney":"Mobile Money",
  "PaywithCash":"Pay with Cash",
  "PayWithMobileMoney":"Pay With Mobile Money",
  "Clear":"Clear",
  "FreeDelivery":"Free Delivery",
  "productsinfewhours":"get your products in few hours",
  "PremiumDelivery":"Premium Delivery",
  "getorderrightaway":"get your order right away",
  "ShowOrderDetail":"Show Order Detail",
  "Youhave":"You have",
  "itemscart":"items in your cart",
  "NoItemFound":"No Item Found",
  "Loading":"Loading",
  "Shop":"Shop",
  "searchNoproductFound":"If you can not find what you are looking for please call  +250788 125 096",
  "Calluson":"Call us on",
  "inputError":"This field should not be empty.",
  "Paywithdebit": "Take products on a debt",
  "TrackOrder": "Track Order"
}